import Plan from '@/models/Plan'
import moment from 'moment'

export const getPlans = async ({ commit }, params = {}) => {
  let {
    page = 1,
    limit = 20,
    search,
    completed = true,
    archived = false,
    sort,
  } = params

  const query = Plan.where('completed', completed || archived)
    .where('archived', archived)
    .orderBy(sort)

  if (params.page) {
    query.page(page).include(['programs'])
  }

  if (params.search) {
    query.where('search', search)
  }

  let { data, meta } = await query.params({ limit }).get()

  commit('setCurentPlanList', data)
  commit('setCurentPlanListMeta', meta)

  return data
}

export const savePlan = ({ commit }, plan) => {
  plan.active_from =
    plan.active_from && moment.utc(moment(plan.active_from)).format()
  plan.active_to = plan.active_to && moment.utc(moment(plan.active_to)).format()
  plan.rollout_at =
    plan.rollout_at && moment.utc(moment(plan.rollout_at)).format()

  return new Plan(plan).save()
}

export const getPlan = ({ commit }, id) => {
  return Plan.include(['programs.variants']).find(id)
}

export function reorderPlans({ commit }, plans) {
  return this.$api.post(`packages/reorder`, { plans })
}

export const archivePlan = async ({ commit }, params) => {
  const plan = new Plan({ id: params.id })
  await Plan.custom(plan, params.setArchive ? 'archive' : 'unarchive').get()
}

export function getPlanIconsList({ commit }) {
  return this.$api.get('packages/icons')
}
